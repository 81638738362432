import './App.css';
import newi from './imgs/new.png'

function App() {
  return (
    <div className="App">
      <h1>Proximamente...</h1>
      <img src={newi} alt="Naraja Electronica" className='newi' />
    </div>
  );
}

export default App;
